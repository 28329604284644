const allowedItemStatuses: Array<string> = JSON.parse(
  process.env.GATSBY_DIRECTUS_ALLOWED_ITEM_STATUSES ?? "[]"
);

export const isStatusAllowed = (status: string) =>
  !!allowedItemStatuses.find((s) => s === status);

export const compareByPresentationOrder = (
  a: { presentationOrder: number },
  b: { presentationOrder: number }
) => {
  const subtract = a.presentationOrder - b.presentationOrder;
  if (subtract < 0) {
    return -1;
  } else if (subtract > 0) {
    return 1;
  }
  return 0;
};

// This assumes that any internal link (intended for Gatsby)
// will start with exactly one slash, and that anything else is external.
export const isInternalHref = (href) => /^\/(?!\/)/.test(href);

export const groupBy = (arr, criteria) => {
  const newObj = arr.reduce(function (acc, currentValue) {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
  return newObj;
};
