import React, {
  AriaAttributes,
  HTMLAttributes,
  ReactElement,
} from "react";
import "./style.css";
import styled, { CSSProperties } from "styled-components";

export type IconClassName =
  | "icon-check"
  | "icon-chevron-down"
  | "icon-chevron-left"
  | "icon-chevron-right"
  | "icon-chevron-up"
  | "icon-external-link"
  | "icon-globe"
  | "icon-home"
  | "icon-info"
  | "icon-mail"
  | "icon-plus"
  | "icon-send"
  | "icon-shield"
  | "icon-user"
  | "icon-x"
  | "icon-flag-britain"
  | "icon-flag-denmark"
  | "icon-flag-finland"
  | "icon-flag-iceland"
  | "icon-flag-norway"
  | "icon-flag-sweden"
  | "icon-arrow-up"
  | "icon-arrow-right"
  | "icon-arrow-down"
  | "icon-arrow-left"
  | "icon-arrow-up2"
  | "icon-arrow-right2"
  | "icon-arrow-down2"
  | "icon-arrow-left2";

const StyledIcon = styled.span<IconProps>
``;

export type IconProps = HTMLAttributes<HTMLSpanElement> &
  Pick<
    AriaAttributes,
    "aria-label" // Label for screen readers.
  > & {
    /** Icon to present. */
    icon: IconClassName;
    styles?: CSSProperties;
  };

const Icon = (props: IconProps): ReactElement => {
  const { icon } = props;
  let children: ReactElement | null = null;

  switch (icon) {
    case "icon-flag-britain":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </>
      );
      break;
    case "icon-flag-denmark":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </>
      );
      break;
    case "icon-flag-finland":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </>
      );
      break;
    case "icon-flag-iceland":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </>
      );
      break;
    case "icon-flag-norway":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
        </>
      );
      break;
    case "icon-flag-sweden":
      children = (
        <>
          <span className="path1" />
          <span className="path2" />
        </>
      );
      break;
    default:
      break;
  }
  return (
    <StyledIcon className={`icon ${props.icon}`} style={props.styles} {...props}>
      {children}
    </StyledIcon>
  );
};

export default Icon;
