import { makeTemplate, TranslationKey } from "./languages";

type Country = {
  label?: string;
};

export const countries = {
  denmark: { countryCode: 'da-DK'},
  iceland: { countryCode: "is-IS"},
  norway: { countryCode: "no-NO"},
  finland: { countryCode: "fi-FI" },
  sweden: {countryCode: "sv-SE" },
};

export type CountryKey = keyof typeof countries;

type TranslationType = {
  [K in CountryKey]: Country;
};
const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": {
    denmark: { label: "Denmark" },
    iceland: { label: "Iceland" },
    norway: { label: "Norway" },
    finland: { label: "Finland" },
    sweden: { label: "Sweden" },
  },
  "da-DK": {
    denmark: { label: "Danmark" },
    iceland: { label: "Island" },
    norway: { label: "Norge" },
    finland: { label: "Finland" },
    sweden: { label: "Sverige" },
  },
  "fi-FI": {
    denmark: { label: "Tanska" },
    iceland: { label: "Islanti" },
    norway: { label: "Norja" },
    finland: { label: "Suomi" },
    sweden: { label: "Ruotsi" },
  },
  "is-IS": {
    denmark: { label: "Danmörk" },
    iceland: { label: "Ísland" },
    norway: { label: "Noregur" },
    finland: { label: "Finnland" },
    sweden: { label: "Svíþjóð" },
  },
  "no-NO": {
    denmark: { label: "Danmark" },
    iceland: { label: "Island" },
    norway: { label: "Norge" },
    finland: { label: "Finland" },
    sweden: { label: "Sverige" },
  },
  "sv-SE": {
    denmark: { label: "Danmark" },
    iceland: { label: "Island" },
    norway: { label: "Norge" },
    finland: { label: "Finland" },
    sweden: { label: "Sverige" },
  },
};

const getCountry = (
  languageCode: TranslationKey,
  country: CountryKey
): Country | undefined => translations[languageCode]?.[country];

export const getCountryLabel = (
  languageCode: TranslationKey,
  country: CountryKey
) =>
  getCountry(languageCode, country)?.label ??
  getCountry("en-GB", country)?.label ??
  "_";
