import React, { FC } from "react";
import styled from "styled-components";
import { colorThemes } from "../../utils/typography";
import Logo, { logos } from "../atoms/logo";

const { logos: logosTheme } = colorThemes;

const logoOptions: any[keyof typeof logos] = [
  "skatte",
  "skatturinn",
  "skatteetaten",
  "vero",
  "skatteVerket",
];

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 10vw;
  background: ${logosTheme.primary};
  & > img {
    margin: 0.5rem 2rem;
  }
`;

const TaxOrganizationLogos: FC = () => {
  return (
    <Container>
      {logoOptions.map((logo: any) => (
        <Logo key={logo} logo={logo} alt={logo as string} height={24} />
      ))}
    </Container>
  );
};

export default TaxOrganizationLogos;
