import React, { FC, HTMLAttributes } from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import styled, { css } from "styled-components";
import { options, colorThemes } from "../../utils/typography";
import { darken, lighten } from "polished";
import { isInternalHref } from "../../utils/misc";

const { link: linkTheme } = colorThemes;
export type LinkStyle = "light" | "dark";

export type LinkProps = Pick<
  GatsbyLinkProps<{}>,
  "to" | "activeClassName" | "partiallyActive"
> & {
  linkStyle?: LinkStyle;
};

type LinkStyleProps = {
  $linkStyle?: LinkStyle;
};

const getPrimary = (linkStyle?: LinkStyle) =>
  linkStyle === "light" ? linkTheme.light.primary : linkTheme.dark.primary;

export const linkStyles = css<LinkStyleProps>`
  text-decoration: none;
  cursor: pointer;
  font-family: ${options.bodyFontFamily?.join(",")};
  font-weight: ${options.bodyWeight};
  background: transparent;
  color: ${({ $linkStyle }) => getPrimary($linkStyle)};
  &:hover {
    text-decoration: underline;
    color: ${({ $linkStyle }) =>
      $linkStyle === "light"
        ? darken(0.2, getPrimary($linkStyle))
        : lighten(0.2, getPrimary($linkStyle))};
  }
  &:active,
  &[data-active="true"] {
    text-decoration: underline;
    color: ${({ $linkStyle }) =>
      $linkStyle === "light"
        ? darken(0.5, getPrimary($linkStyle))
        : lighten(0.5, getPrimary($linkStyle))};
  }
`;

const StyledGatsbyLink = styled(GatsbyLink)<LinkStyleProps>`
  ${linkStyles}
`;

const StyledA = styled.a<HTMLAttributes<HTMLLinkElement> & LinkStyleProps>`
  ${linkStyles}
`;

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link: FC<LinkProps> = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  linkStyle = "dark",
  ...other
}) => {
  // This assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = isInternalHref(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <StyledGatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        $linkStyle={linkStyle}
        {...other}
      >
        {children}
      </StyledGatsbyLink>
    );
  }
  return (
    <StyledA href={to} $linkStyle={linkStyle} {...other} target="_blank">
      {children}
    </StyledA>
  );
};

export default Link;
