import React from "react";
import { Element } from "domhandler/lib/node";
import Link from "../components/atoms/link";
import { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import { ListLink } from "../components/molecules/links";
import { rootPagePath } from "./path";
import { isInternalHref } from "./misc";

export type LinkType = {
  to: string;
  text: string;
};

export const isElementNode = (domNode: DOMNode) => domNode instanceof Element;
export const isLinkTag = (domNode: DOMNode) =>
  isElementNode(domNode) && /^[A,a]$/i.test((domNode as Element).tagName);
export const isPTag = (domNode: DOMNode) =>
  isElementNode(domNode) && /^[P,p]$/i.test((domNode as Element).tagName);

export const localizeHref = (href: string, languageCode: string): string => {
  if (!isInternalHref(href)) {
    return href;
  }
  if (href === "/") {
    return href;
  }
  const hrefParts = href.split("/").filter((c) => !!c);
  let localizedHref = href;
  if (href.startsWith(`/${rootPagePath}`) || href.startsWith(rootPagePath)) {
    localizedHref = href.replace(
      `/${rootPagePath}`,
      `/${rootPagePath}${languageCode}/`
    );
  } else {
    // Redirect all Directus local links to /{rootPagePath}/language path.
    localizedHref = `/${rootPagePath}${languageCode}/${hrefParts.join("/")}`;
  }

  // Links won't work unless they have a / at the end
  // Exception: Can't add / at the end of url-parameter strings; They won't work like that
  if(localizedHref && localizedHref[localizedHref.length - 1] !== '/' && !localizedHref.includes('?')) {
    localizedHref += '/';
  }

  return localizedHref;
};

export const createLocalizedLink = (
  linkTag: Element,
  languageCode: string
): LinkType => {
  const { children, attribs } = linkTag;
  const { href } = attribs;

  const text = ((children[0] as unknown) as Text)?.data;
  let localizedHref = localizeHref(href, languageCode);

  return { to: localizedHref, text };
};

export const createGatsbyLink = (linkTag: Element, languageCode: string) => {
  const { to, text } = createLocalizedLink(linkTag, languageCode);
  return <Link to={to}>{text}</Link>;
};

export const createGatsbyListLink = (
  linkTag: Element,
  languageCode: string
) => {
  const { to, text } = createLocalizedLink(linkTag, languageCode);
  return <ListLink link={{ to, text }} />;
};

export const createHtmlParserOptions = (
  languageCode: string
): HTMLReactParserOptions => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        // Replace internal links with Gatsby links and add localization to the URI path.
        if (isLinkTag(domNode)) {
          const link = createGatsbyLink(domNode, languageCode);
          return link;
        }
      }
    },
  };
  return options;
};
