import React, { FC, useState, useEffect } from "react";
import { CSSProperties } from "styled-components";
import { TranslationKey } from "../../translations/languages";
import { getTranslation } from "../../translations/header";

export const contentId = "content";
export const selectionContentId = "selection-content";

type LinkToContentProps = {
    languageCode?: TranslationKey
};

const commonStyle : CSSProperties = {
    width: '1px',
    height: '1px',
    padding: 0,
    overflow: 'hidden',
    border: 0,
    fontSize: '18px'
};

const hidden : CSSProperties = {
    position: 'absolute',
    margin: '-1px',
    clip: 'rect(0,0,0,0)'
};

export const LinkToContent: FC<LinkToContentProps> = (props: LinkToContentProps) => {
    const languageCode = props.languageCode || "en-GB";
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const [goToContent, setGoToContent] = useState("");

    useEffect(() => {
        const goText = getTranslation(languageCode, "linkToContent");
        setGoToContent(goText);
    }, [languageCode]);

    
    let selection : HTMLElement | null = null;
    if (typeof window !== `undefined`) {
        selection = document.getElementById(selectionContentId);
    }

    const goTo = selection ? selectionContentId : contentId;
    return (
        <div style={{ textAlign: 'center' }}>
            <a href="#content" onFocus={onFocus} onBlur={onBlur} style={!focused ? { ...commonStyle, ...hidden } : commonStyle}> {goToContent} </a>
        </div>
    );
}

export default LinkToContent;