import React, { FC } from "react";
import { Separator } from "reakit";
import styled from "styled-components";
import Icon from "../atoms/icon";
import Link from "../atoms/link";
import { colorThemes } from "../../utils/typography";

const {
  separator: { primary },
} = colorThemes;

export type LinkType = {
  to: string;
  text: string;
};

export type LinksProps = {
  links: Array<LinkType>;
};

const Container = styled.nav`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  height: 100%;
  width: 100%;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  & > :first-child {
    justify-self: flex-start;
    margin-right: auto;
  }
  padding: 1rem 0;
`;

const StyledSeparator = styled(Separator)`
  border: 0px;
  border-top: 3px solid ${primary};
  background: transparent;
  width: 100%;
`;

type ListLinkProps = {
  link: LinkType;
};

export const ListLink: FC<ListLinkProps> = ({ link: { to, text } }) => (
  <>
    <LinkContainer >
      <Link to={to} >{text}</Link>
      <Icon icon="icon-arrow-right" aria-hidden />
    </LinkContainer>
    <StyledSeparator orientation="horizontal" aria-hidden />
  </>
);

const Links: FC<LinksProps> = ({ links, ...rest }) => {
  return (
    <Container style={{height:'auto'}} {...rest}>
      {links.map(({ to, text }, i) => (
        <ListLink key={i} link={{ to, text }} />
      ))}
    </Container>
  );
};

export default Links;
