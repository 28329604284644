import React, { FC, ReactElement, useCallback, useState } from "react";
import { graphql, navigate, StaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import queryString from "query-string";

import { RenderCallback } from "../../../utils/types/misc";
import {
  useLanguageDispatch,
  useLanguageState,
} from "../../../utils/contexts/languageContext";
import { Language, LanguageIconType, LanguagesProps, LanguagesType } from ".";

type DirectusLanguage = {
  name: string;
  code: string;
  flag: { type: string; filename_download: string };
  presentation_order: number;
  has_qa: boolean;
};

export type LanguageSelectionHandlerProps = {
  render: RenderCallback<Pick<LanguagesProps, "onLanguageSelected">>;
  navigateTo?: string;
  languages: LanguagesType;
};

export const LanguageSelectionHandler: FC<LanguageSelectionHandlerProps> = ({
  render,
  navigateTo,
  languages,
}: LanguageSelectionHandlerProps) => {
  const location = useLocation();
  const { search, pathname } = location;
 
  const setLanguage = useLanguageDispatch();

  const onLanguageSelectedHandler = useCallback(
    (languageCode: string) => {
      let newPath = "";
      if (navigateTo) {
        newPath = navigateTo;
      } else {
        // Replace any known language code in current path with the language code selected now.
        languages.forEach((lang) => {
          const replaced = pathname.replace(lang.code, languageCode);
        
          // Don't take current path language code in account.
          if (replaced !== pathname) {
            newPath = replaced;
          }
        });
        if(search) {
          newPath += search;
        }
      }

      setLanguage({ languageCode });
      if (newPath !== pathname) {
        navigate(newPath);
      }
    },
    [pathname, setLanguage, navigate, languages]
  );

  return render({ onLanguageSelected: onLanguageSelectedHandler });
};

export type LanguagesWithBackendProps = {
  render: RenderCallback<Pick<LanguagesProps, "languages" | "languageIcons">>;
};

type DirectusData = {
  directus: { languages: Array<DirectusLanguage> };
  allFile: { nodes: LanguageIconType[] };
};

export const LanguagesWithBackend: FC<LanguagesWithBackendProps> = ({
  render,
}: LanguagesWithBackendProps): ReactElement<any, any> | null => {
  return (
    <StaticQuery
      query={graphql`
        {
          directus {
            languages {
              code
              name
              presentation_order
              has_qa
              flag {
                type
                filename_download
                filename_disk
              }
            }
          }
          allFile {
            nodes {
              publicURL
              name
              ext
            }
          }
        }
      `}
      render={({
        directus: { languages },
        allFile: { nodes },
      }: DirectusData) => {
        const [languageIcons] = useState(
          nodes
            .filter((c) => c.ext?.toLowerCase() === ".svg")
            .map((c) => ({ ...c, fullName: `${c.name}${c.ext}` }))
        );

        const mappedLanguages: Array<Language> = languages.map(
          ({ code, flag, name, presentation_order, has_qa }) => ({
            code,
            flag: flag ?? { type: "", filename_download: "" },
            name,
            presentationOrder: presentation_order,
            hasQa: has_qa,
          })
        );

        return render({ languages: mappedLanguages, languageIcons });
      }}
    />
  );
};
