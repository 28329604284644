import Typography, { TypographyOptions } from "typography";

export const colors = {
  blue: ["#00104D", "#272F58", "#1A225C", "#2153BD", "#959AAA"],
  white: [
    "#C9CBD4",
    "#D6D7DD",
    "#EBECED",
    "#EFF3F9",
    "#F7F7F7",
    "#F7F9FC",
    "#FCFCFC",
    "#FFFFFF",
  ],
  yellow: ["#F1C300"],
  red: ["#D50C0C"],
  green: ["#40821C"],
};

export const colorThemes = {
  button: {
    primary: colors.white[colors.white.length - 1],
    secondary: colors.blue[0],
    border: colors.blue[colors.blue.length - 1],
  },
  selectMenu: {
    primary: colors.white[colors.white.length - 1],
    secondary: colors.blue[0],
    border: colors.blue[colors.blue.length - 1],
  },
  header: {
    primary: colors.white[colors.white.length - 3],
  },
  footer: {
    primary: colors.blue[0],
    secondary: colors.white[colors.white.length - 4],
  },
  logos: {
    primary: colors.white[colors.white.length - 2],
  },
  link: {
    dark: {
      primary: colors.blue[0],
    },
    light: {
      primary: colors.white[colors.white.length - 4],
    },
  },
  separator: {
    primary: colors.white[2],
  },
  eitherOneSelector: {
    primary: colors.white[3],
    border: {
      primary: colors.white[1],
      secondary: colors.blue[colors.blue.length - 2],
    },
    hover: colors.white[colors.white.length - 3],
  },
  subTopics: {
    primary: colors.white[colors.white.length - 3],
    secondary: colors.blue[2],
  },
  expander: {
    primary: colors.white[colors.white.length - 2],
  },
  selectCountry: {
    primary: colors.white[colors.white.length - 2],
  },
  breadcrumb: {
    primary: colors.blue[1],
    secondary: colors.blue[colors.blue.length - 1],
  },
  anonymousFeedback: {
    primary: colors.white[4],
  },
  textInput: {
    primary: colors.blue[0],
    border: colors.white[1],
  },
  topics: {
    primary: colors.white[colors.white.length - 3],
    secondary: colors.blue[2],
  },
  siteMap: {
    primary: colors.blue[colors.blue.length - 2],
  },
};

export const mediaBreakpoints = {
  xs: "0",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const etaxTheme: TypographyOptions = {
  baseFontSize: "16px",
  baseLineHeight: 1.45,
  scaleRatio: 1.4,
  googleFonts: [
    {
      name: "Roboto",
      styles: ["400"],
    },
  ],
  headerFontFamily: ["Roboto", "sans-serif"],
  bodyFontFamily: ["Roboto", "sans-serif"],
  headerColor: colors.blue[0],
  bodyColor: colors.blue[0],
  headerWeight: 700,
  bodyWeight: 400,
  boldWeight: 600,
  includeNormalize: true,
};

const typography = new Typography(etaxTheme);

export const { scale, rhythm, options } = typography;
export default typography;
