import React, { FC } from "react";
import styled from "styled-components";
import { options, colorThemes } from "../../utils/typography";
import Link from "../atoms/link";
import Logo from "../atoms/logo";
import { mediaBreakpoints } from "../../utils/typography";

const { md, lg, xl, xxl } = mediaBreakpoints;

const { header: headerTheme } = colorThemes;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  & > :first-child {
    justify-self: flex-start;
    margin-right: auto;
  }
  padding: 0.5rem 1.5rem;
  text-align: center;
  background: ${headerTheme.primary};
  color: ${options.headerColor};

  & img {
    margin: 0;
  }

  @media only screen and (min-width: ${md}) {
    padding: 0.5rem 3rem;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationBar: FC = ({ children }) => {
  return (
    <Container>
      <StyledLink to="/">
        <Logo
          aria-label="Nordisk Etax logo"
          alt="Nordisk Etax"
          logo="nordiskEtax"
          height={56}
          style={{ position: "relative", right: "10px" }}
        />
      </StyledLink>
      {children}
    </Container>
  );
};

export default NavigationBar;
