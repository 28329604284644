import React, { FC } from "react";
import styled from "styled-components";
import { colorThemes, mediaBreakpoints } from "../../../utils/typography";
import Icon from "../../atoms/icon";
import Link from "../../atoms/link";
import TaxOrganizationLogos from "../taxOrganizationLogos";
import { compareByPresentationOrder } from "../../../utils/misc";
import { FooterWithBackend } from "./decorators";

const { footer: footerTheme } = colorThemes;
const { md } = mediaBreakpoints;

export type FooterLink = {
  id: string;
  presentationOrder: number;
  text: string;
  url: string;
};

export type FooterProps = {
  links: Array<FooterLink>;
};

const Container = styled.footer`
  display: flex;
  flex-flow: column nowrap;
  background: ${footerTheme.primary};
  color: ${footerTheme.secondary};

  width: 100%;
`;

const Links = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 2rem 20vw;
  justify-content: center;
  @media only screen and (min-width: ${md}) {
    justify-content: space-evenly;
  }
  & > * {
    text-align: center;
    width: 100%;
    margin: 1rem;
    @media only screen and (min-width: ${md}) {
      width: auto;
    }
  }
`;

const StyledLink = styled(Link)`
  & {
    text-decoration: none;
  }
  & > :first-child {
    margin-right: 0.25rem;
  }

  &:hover,
  &:active {
    text-decoration: none;
    & > .txt {
      text-decoration: underline;
    }
  }
`;

export const Footer: FC<FooterProps> = ({ links }) => {
  return (
    <Container>
      <div>
        {links && links.length > 0 && (
          <Links>
            {links.sort(compareByPresentationOrder).map((c, i) => (
              <StyledLink key={c.id} to={c.url} linkStyle="light">
                <Icon aria-hidden icon="icon-arrow-right" styles={{fontSize:"10px"}} />
                <span className="txt">{c.text}</span>
              </StyledLink>
            ))}
          </Links>
        )}
      </div>
      <TaxOrganizationLogos />
    </Container>
  );
};

const WithBackend: FC = () => (
  <FooterWithBackend render={({ links }) => <Footer links={links} />} />
);

export default WithBackend;
