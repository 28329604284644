import React from "react";
import { Button as ReakitButton } from "reakit";
import styled from "styled-components";
import { darken, lighten, transparentize } from "polished";
import {
  options,
  colorThemes,
  mediaBreakpoints,
} from "../../../utils/typography";

const { button: buttonTheme } = colorThemes;
const { md } = mediaBreakpoints;

const BaseButton = styled(ReakitButton)`
  outline: 0;
  color: ${options.bodyColor};
  background: ${buttonTheme.primary};
  padding: 0.375em 0.75em;
  line-height: 1.5;
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: ${buttonTheme.border};
  border-style: solid;
  cursor: pointer;
  font-size: ${options.baseFontSize};
  font-family: ${options.bodyFontFamily?.join(",")};
  font-weight: ${options.bodyWeight};
  line-height: ${options.baseLineHeight};
  &[disabled],
  &[aria-disabled="true"] {
    cursor: auto;
    opacity: 0.5;
  }

  height: 3rem;
  width: 100%;

  @media only screen and (min-width: ${md}) {
    width: auto;
  }

  &:focus {
    border-color: ${buttonTheme.border};
    box-shadow: 0 0 0 0.08em ${buttonTheme.secondary};
  }

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    &:hover {
      border-color: ${buttonTheme.secondary};
      box-shadow: 0 0 0 0.08em ${buttonTheme.secondary};
    }
    &:active,
    &[data-active="true"] {
      color: ${buttonTheme.secondary};
      border-color: ${buttonTheme.secondary};
      box-shadow: 0 0 0 0.1em ${buttonTheme.secondary};
    }
  }

  & .icon {
    vertical-align: middle;
  }
`;

export default BaseButton;
