import styled, { css } from "styled-components";
import {
  mediaBreakpoints,
  colorThemes,
  options,
  rhythm,
} from "../../utils/typography";

const {
  headerColor,
  headerFontFamily,
  headerWeight,
  bodyColor,
  bodyWeight,
  bodyFontFamily,
} = options;
const { md, lg, xl, xxl } = mediaBreakpoints;

export const PageContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const pageContentStyles = css`
  display: flex;
  flex-flow: column nowrap;
  margin: 3rem auto;
  padding: 1rem 1rem;
  width: 100%;
  @media only screen and (min-width: ${md}) {
    width: ${`calc(${md} - 5rem)`};
  }
  @media only screen and (min-width: ${lg}) {
    width: ${`calc(${lg} - 10rem)`};
  }
  @media only screen and (min-width: ${xl}) {
    width: ${`calc(${xl} - 10rem)`};
  }
  @media only screen and (min-width: ${xxl}) {
    width: ${`calc(${xxl} - 10rem)`};
  }
`;

export const Main = styled.main`
  ${pageContentStyles};
  a {
    text-decoration: underline;
  }
`;

export const headerTextStyles = css`
  color: ${headerColor};
  font-family: ${headerFontFamily?.join(",")};
  font-weight: ${headerWeight};
`;

export const bodyTextStyles = css`
  color: ${bodyColor};
  font-family: ${bodyFontFamily?.join(",")};
  font-weight: ${bodyWeight};
`;
