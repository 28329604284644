import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import { FooterLink, FooterProps } from ".";
import { useLanguageState } from "../../../utils/contexts/languageContext";
import { isStatusAllowed } from "../../../utils/misc";
import { RenderCallback } from "../../../utils/types/misc";
import { localizeHref } from "../../../utils/htmlParser";

export type FooterWithBackendWithBackendProps = {
  render: RenderCallback<FooterProps>;
};

type DirectusFooterLink = {
  id: string;
  status: string;
  presentation_order: number;
  url: string;
  translations: Array<{
    languages_code: {
      code: string;
    };
    link_text: string;
  }>;
};

type DirectusData = {
  directus: { footer_links: Array<DirectusFooterLink> };
};

export const FooterWithBackend: FC<FooterWithBackendWithBackendProps> = ({
  render,
}) => {
  return (
    <StaticQuery
      query={graphql`
        {
          directus {
            footer_links(
              limit: -1
              filter: { status: { _in: ["published", "draft"] } }
            ) {
              id
              status
              presentation_order
              url
              translations {
                languages_code {
                  code
                }
                link_text
              }
            }
          }
        }
      `}
      render={({ directus: { footer_links } }: DirectusData) => {
        const { languageCode: currentLanguage } = useLanguageState();
        const links = footer_links
          .filter((c) => isStatusAllowed(c.status))
          .map(({ id, translations, presentation_order, url }) => {
            const footerTranslation = translations.find(
              (t: { languages_code: { code: string } }) =>
                t.languages_code?.code == currentLanguage
            );

            if (!footerTranslation) {
              return null;
            }

            const link: FooterLink = {
              id,
              url: localizeHref(url, currentLanguage),
              text: footerTranslation?.link_text,
              presentationOrder: presentation_order,
            };
            return link;
          })
          .filter((c) => !!c) as FooterLink[];

        return render({ links: links });
      }}
    />
  );
};
