export const languages = {
  "en-GB": {},
  "da-DK": {},
  "fi-FI": {},
  "is-IS": {},
  "no-NO": {},
  "sv-SE": {},
};

export const chooseLanguages = {
  'da-DK': {text: 'Vælg sprog'},
  'is-IS': {text: 'Veldu tungumál'},
  'no-NO': {text: 'Velg språk'},
  'fi-FI': {text: 'Valitse kieli'},
  'sv-SE': {text: 'Välj språk'},
  'en-GB': {text: 'Choose language'},
};

export type TranslationKey = keyof typeof languages;

const copy = (val: any) => JSON.parse(JSON.stringify(val));

export const makeTemplate = <T>(template?: T): Record<TranslationKey, T> => {
  const result: Record<TranslationKey, T> = copy(languages);
  if (template) {
    Object.keys(result).forEach((key) => {
      result[key] = copy(template);
    });
  }
  return result;
};
