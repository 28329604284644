import React, { AriaAttributes, ButtonHTMLAttributes, ReactNode } from "react";
import BaseButton from "./basebutton";
import styled from "styled-components";

type FlexContainerProps = {
  contentCount: number;
};

const FlexContainer = styled.span<FlexContainerProps>`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  & .left {
    margin-right: auto;
  }

  & .right {
    margin-left: auto;
  }
`;

const FlexItem = styled.span`
  flex-basis: auto;
  display: flex;
  align-items: center;
  padding: ${({ padding }: { padding?: string }): string | undefined =>
    padding};
`;

type ContentType = ReactNode;

export type TextIconButtonProps = Pick<AriaAttributes, "aria-label"> &
  Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    "className" | "onClick" | "disabled" | "type"
  > & {
    contentLeft?: ContentType;
    contentCenter?: ContentType;
    contentRight?: ContentType;
  };

const TextIconButton = React.forwardRef<HTMLButtonElement, TextIconButtonProps>(
  (
    { contentLeft, contentCenter, contentRight, className, onClick, ...rest },
    ref
  ) => {
    const contentCount = [contentLeft, contentCenter, contentRight].filter(
      (c) => !!c
    ).length;
    const edgePadding = contentCount > 1 && !contentCenter ? "0.5em" : 0;
    return (
      <BaseButton ref={ref} className={className} onClick={onClick} {...rest}>
        <FlexContainer contentCount={contentCount}>
          {contentLeft && (
            <FlexItem
              padding={`0 ${edgePadding} 0 0`}
              className={"content_left " + (contentCount == 2 ? "left" : "")}
            >
              {contentLeft}
            </FlexItem>
          )}
          {contentCenter && (
            <FlexItem
              padding={`0 0.5em 0 0.5em`}
              className="content_center left right"
            >
              {contentCenter}
            </FlexItem>
          )}
          {contentRight && (
            <FlexItem
              padding={`0 0 0 ${edgePadding}`}
              className={"content_right " + (contentCount == 2 ? "right" : "")}
            >
              {contentRight}
            </FlexItem>
          )}
        </FlexContainer>
      </BaseButton>
    );
  }
);

export default TextIconButton;
