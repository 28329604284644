import React, {
  AllHTMLAttributes,
  FC,
  HTMLAttributes,
  ImgHTMLAttributes,
} from "react";
import styled from "styled-components";

// Logos
import vero from "./img/logo-vero.svg";
import nordiskEtax from "./img/nordisk_etax_logo.svg";
import nordiskEtaxBlack from "./img/nordisk_etax_logo_black.svg";
import nordiskEtaxPaddingArea from "./img/nordisk_etax_logo_padding_area.svg";
import nordiskEtaxWhite from "./img/nordisk_etax_logo_white.svg";
import skatte from "./img/skatte-logo.svg";
import skatteetaten from "./img/skatteetaten.jpg";
import skatteVerket from "./img/SkatteverketLogo.png";
import skatturinn from "./img/skatturinn_logo.png";

export const logos = {
  vero,
  nordiskEtax,
  nordiskEtaxBlack,
  nordiskEtaxPaddingArea,
  nordiskEtaxWhite,
  skatte,
  skatteetaten,
  skatteVerket,
  skatturinn,
};

type LogoImgProps = Partial<
  Pick<AllHTMLAttributes<HTMLImageElement>, "height" | "width">
> &
  HTMLAttributes<HTMLImageElement> &
  Pick<ImgHTMLAttributes<HTMLImageElement>, "alt">;

const LogoImg = styled.img`
  && {
    height: ${({ height }) => `${height}px`};
    margin-right: 1rem;
  }
`;

export type LogoProps = LogoImgProps & {
  /** Name of the logo */
  logo: keyof typeof logos;
};

const Logo: FC<LogoProps> = ({
  logo,
  alt,
  height = 32,
  ...rest
}: LogoProps) => (
  <LogoImg height={height} src={logos[logo]} alt={alt} {...rest} />
);

export default Logo;
