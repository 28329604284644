import { makeTemplate, TranslationKey } from "./languages";

type TranslationType = { linkToContent?: string, header?: string; subTitle?: string };
const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": {
    linkToContent: "Go to content",
    header:
      "Nordisk eTax is for you who live in a Nordic country and have income or assets in another Nordic country.",
    subTitle:
      "Tax administrations of Denmark, Iceland, Norway, Finland and Sweden, are jointly responsible for the content.",
  },
  "da-DK": {
    linkToContent: "Gå til indhold",
    header:
      "Nordisk eTax er for dig der bor i et nordisk land og har indkomst eller formue fra et andet nordisk land.",
    subTitle:
      "Skattemyndighederne i Danmark, Island, Norge, Finland og Sverige er i fælleskab ansvarlige for indholdet.",
  },
  "fi-FI": {
    linkToContent: "Siirry sisältöön",
    header:
      "Nordisk eTax on tarkoitettu sinulle, joka asut yhdessä Pohjoismaassa ja jolla on tuloja tai omaisuutta toisessa Pohjoismaassa.",
    subTitle:
      "Tanskan, Islannin, Norjan, Suomen ja Ruotsin verohallinnot vastaavat yhdessä palvelun sisällöstä.",
  },
  "is-IS": {
    linkToContent: "Opna efnið",
    header:
      "Nordisk eTax er fyrir þig sem býrð í norrænu landi og færð tekjur eða átt eignir í öðru norrænu landi",
    subTitle:
      "Skattyfirvöld í Danmörku, Íslandi, Noregi, Finnlandi og Svíþjóð bera sameiginlega ábyrgð á efninu.",
  },
  "no-NO": {
    linkToContent: "Gå til innholdet",
    header:
      "Nordisk eTax er for deg som bor i et Nordisk land og har inntekt eller formue i et annet nordisk land.",
    subTitle:
      "Skattemyndighetene i Danmark, Island, Norge, Finland og Sverige har et felles ansvar for innholdet.",
  },
  "sv-SE": {
    linkToContent: "Gå till innehåll",
    header:
      "Nordisk eTax vänder sig till dig som är bosatt i ett nordiskt land och har inkomster eller tillgångar i ett annat nordiskt land. ",
    subTitle:
      "Skattemyndigheterna i Danmark, Island, Norge, Finland och Sverige är gemensamt ansvariga för innehållet. ",
  },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => {
  const langObj = translations[languageCode];
  return langObj?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
};
