import { Element, DataNode } from "domhandler/lib/node";
import { countries, CountryKey } from "../translations/countries";
import { SelectionType } from "../components/molecules/eitherOneSelector";
import {DirectusTaxTopic} from "../templates/taxationPageTemplate"

// Ids for pages that, if page ID is started with, will reside in a sub directory of same name.
const directories: Array<string> = JSON.parse(
  process.env.GATSBY_PAGE_DIRECTORIES ?? "[]"
);

export const rootPagePath = process.env.GATSBY_ROOT_PAGE_PATH ?? "";

/**
 * Converts given page ID to URI path. If ID is of certain kind, it will be splitted into directory structure.
 * // Duplicate found in gatsby-node because or commonjs vs esm.
 * @param pageId Page ID to be converted to URI path.
 */

export const convertPageIdToPath = (languageCode: string, pageId: string) => {
  const idParts = pageId.split("-");
  let path = `${rootPagePath}${languageCode}/`;

  if (!path.startsWith("/")) {
    path = `/${path}`;
  }

  // Determine if page should be in sub-directory.
  if (directories.find((c) => c === idParts[0])) {
    path += idParts.join("/");
  } else {
    path += `${pageId}`;
  }

  if (!path.endsWith("/")) {
    path += "/";
  }

  return path;
};

export const findTextChildren = (node: Element) => {
  let textChildren: DataNode[] = [];
  if (!node.children || node.children.length === 0) {
    return textChildren;
  }
  node.children.forEach((child) => {
    if (child.type === "text") {
      textChildren.push(child as DataNode);
    } else {
      textChildren = [...textChildren, ...findTextChildren(child as Element)];
    }
  });
  return textChildren;
};

export const tryFindBreadcrumbLabel = (
  domNode: Element,
  pageId: string
): { level?: number; header?: string } => {
  const { children, attribs } = domNode;
  const isHeader = /^[H,h]\d{1}$/.test(domNode.tagName);
  if (isHeader) {
    const match = domNode.tagName.match(/\d+$/);
    if (match && match.length > 0 && children) {
      const level = parseInt(match[0]);
      const textChildren = findTextChildren(domNode)
        .filter((c) => !!c.data)
        .map((c) => c?.data?.trim());
      const headerText = textChildren.join(" ");
      const header = !!headerText ? headerText : pageId;
      return { level, header };
    }
  }
  return {};
};

export const findCountryCodeInPathName= (pathName: string) => {
  if (!pathName) {
    return undefined;
  }

  const countryCodeMatch = Object.keys(countries).find((c) => {
    if (pathName.includes(countries[c].countryCode)) {
      return true;
    }
  });

  if(countryCodeMatch)
    return countries[countryCodeMatch].countryCode;
  // Can't add GB to countries because it isn't available in other places where "countries" is used
  else if (pathName.includes("en-GB"))
    return "en-GB"
  return undefined;
};

export const findCountryInPathname = (pathName: string) => {
  if (!pathName) {
    return undefined;
  }

  const countryMatch = Object.keys(countries).find((c) => {
    if (pathName.includes(c)) {
      return true;
    }
  });

  return countryMatch as CountryKey | undefined;
};

export const findTopicInPathname = (pathName: string, topics: Array<DirectusTaxTopic>) => {
  if (!pathName) {
    return undefined;
  }

  const topicMatch = topics.find((t) => {
    if (pathName.includes(t.id)) {
      return true;
    }
  });

  return topicMatch?.id as string | undefined;
};

export const findSelectionFromPathName = (pathName: string, startPath: string) => {
  if (!pathName) {
    return "one";
  }
  const rest = pathName.replace(startPath, "");

  const oneOrAnother = ["one", "another"].find((s) => {
    if (rest.includes(s)) {
      return true;
    }
  });

  return oneOrAnother as SelectionType || "one";
};

export const getViewpointPathname = (
  country: CountryKey,
  languageCode: string,
  viewpointTemplate: string
) => {
  const viewpoint = viewpointTemplate.replace("{country}", country);
  const viewpointPathname = convertPageIdToPath(languageCode, viewpoint);

  return viewpointPathname;
};

export const findViewpointTemplate = (
  pathname: string,
  country: CountryKey,
  languageCode: string,
  viewpointTemplates: string[]
): string | null => {
  let result: string | null = null;
  viewpointTemplates.every((c) => {
    const viewpointPathname = getViewpointPathname(country, languageCode, c);
    if (!pathname.endsWith("/")) {
      pathname += "/";
    }
    if (pathname === viewpointPathname) {
      result = c;
      return false;
    }
    return true;
  });

  return result;
};
type Viewpoint = {
  country_a_page_id_template: string;
  country_b_page_id_template: string;
  country_a_header: string;
  country_b_header: string;
  viewpoint_selection_title: string;
  viewpoint_info_html?: string;
};

export const getInitialViewpoint = (
  viewpoint: Viewpoint,
  selectedViewpointTemplate: string
) => {
  let initialViewpoint: SelectionType = "one";
  if (!viewpoint || !selectedViewpointTemplate) {
    return initialViewpoint;
  }
  if (selectedViewpointTemplate === viewpoint.country_a_page_id_template) {
    initialViewpoint = "one";
  } else if (
    selectedViewpointTemplate === viewpoint.country_b_page_id_template
  ) {
    initialViewpoint = "another";
  }
  return initialViewpoint;
};
